body {
  margin: 0;
  padding: 0;
  background: white;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
